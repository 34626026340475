import React from "react"
import { Parallax } from 'react-scroll-parallax'

const Hero = () => {
  return (
    <div className="page page--hero">
      <Parallax className="page__hero" y={[0, 0]}>
          <h2>Solicite um pagamento, <br />receba rápido.</h2>

          <div className="hero__description">
            <p>Payme é a forma mais moderna, rápida e segura de receber dinheiro de alguém na internet.</p>
            <p>Já mencionei que é de <strong>graça?</strong></p>
          </div>
      </Parallax>
    </div>
  )
}

export default Hero
